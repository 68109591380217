.App {
  text-align: center;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.pulse {
  animation: pulse-animation 2s 2s infinite;
}

.marker-animation {
  transition: 0.5s;
  transition-delay: 100ms;
}

.marker {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pointer {
  position: absolute;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  border: 4px solid #1565c0;
}

.square {
  position: absolute;
  width: 50px;
  height: 50px;
  border: 4px solid #1565c0;
  border-radius: 10px;
  /* resize: both; */
  /* overflow: auto; */
}

.scenes {
  display: flex;
}

.scene {
  width: 50px;
  height: 50px;
  justify-content: center;
}

.modal {
  background-color: #1565c0;
  width: fit-content;
  color: black;
  border-radius: 10px;
  font-size: 0.75rem;
  max-width: 250px;
  color: rgb(107 114 128);
  text-align: left;
}

.modal-header {
  padding: 10px;
  background-color: #1565c0;
  color: white;
  margin: 0px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.helper {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 40px;
  display: none;
  cursor: pointer;
}

.helper:hover {
  display: block;
}

.marker:hover ~ .helper {
  display: block;
}

.navigate-button {
  height: 100px;
  position: absolute !important;
  bottom: 5%;
}

.navigate-button-next {
  right: 10px;
}

.navigate-button-prev {
  left: 10px;
}

.large-file-upload {
  height: 100px;
  width: 100px;
  background: #e2e2e2;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  color: white;
}

.large-file-upload:hover {
  background: #d3d3d3;
}

.marker-action-button {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.marker-action-button-text {
  font-size: 0.7rem;
  padding: 0px;
  margin: 0px;
}

.marker-action-bar {
  background: #eee;
  width: fit-content;
  margin-right: 20px;
  display: flex;
  border-radius: 10px;
  align-self: center;
  padding: 0px 10px;
  border: 1px solid #d1d1d1;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  padding-bottom: 10px;
  font-size: 20px;
  display: flex;
  justify-content: center;
}

.action-button {
  color: black !important;
}
.selected-action-button {
  color: #1976d2 !important;
}

.scene-clear-button {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  position: absolute;
  top: -12.5px;
  right: -7px;
  background-color: #1565c0;
  border: 1px solid white;
  z-index: 9;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
}

.scene-clear-button:hover {
  transform: rotate(180deg);
}
