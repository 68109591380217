.montage-list-item-title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.montage-list-item {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
}

.montage-list-item:hover {
  cursor: pointer;
}

.container {
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.item {
  padding: 5px;
}

.montage-list-item:hover {
  opacity: 0.7;
}

@media (max-width: 700px) {
  .container {
    grid-template-columns: 3fr;
  }
}

@media (min-width: 1300px) {
  .container {
    grid-template-columns: repeat(4, 1fr);
  }
}
