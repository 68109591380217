body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(242, 242, 242);
  height: 100%;
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.marker-text-modal {
  display: block;
}

.marker-text-modal-mobile {
  display: none;
  text-align: left;
}
.montage-navigation-buttons {
  display: block;
}

@media (max-width: 600px) {
  .marker-text-modal-mobile {
    display: flex;
    justify-content: space-between;
    text-align: left;
  }

  .marker-text-modal {
    display: none !important;
  }

  .montage-navigation-buttons {
    display: none !important;
  }
}
