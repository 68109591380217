.App {
  text-align: center;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.pulse {
  animation: pulse-animation 2s 2s infinite;
}

.marker-animation {
  transition: 0.5s;
  transition-delay: 100ms;
}

.marker {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.scenes {
  display: flex;
}

.scene {
  width: 50px;
  height: 50px;
  justify-content: center;
}

.modal {
  background-color: #1565c0;
  width: fit-content;
  color: black;
  border-radius: 10px;
  font-size: 0.85rem;
  max-width: 250px;
  color: rgb(107 114 128);
  text-align: left;
  opacity: 0;
  animation: opacityAnimation 0.8s 0.2s forwards;
}

@keyframes opacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal-header {
  padding: 10px;
  background-color: #1565c0;
  color: white;
  margin: 0px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.helper {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 40px;
  display: none;
  cursor: pointer;
}

.helper:hover {
  display: block;
}

.marker:hover ~ .helper {
  display: block;
}

.navigate-button {
  height: 100px;
  position: absolute !important;
  bottom: 5%;
}

.navigate-button-next {
  right: 10px;
}

.navigate-button-prev {
  left: 10px;
}

.svg-pointer {
  position: absolute;
  pointer-events: none;
  /* left: calc(100% + 0px); */
  /* transform: rotate(-90deg); */
  fill: #1565c0;
  /* top: 53px; */
}

.svg-pointer-left {
  transform: rotate(-90deg);
}
.svg-pointer-right {
  transform: rotate(-270deg);
}
.svg-pointer-top {
  transform: rotate(-0deg);
}
.svg-pointer-bottom {
  transform: rotate(-180deg);
}
