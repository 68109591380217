.App {
  text-align: center;
}

.App-header {
  background-color: transparent;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.pulse {
  animation: pulse-animation 2s 2s infinite;
}

.marker-selected {
  border-color: black !important;
  border-style: dashed !important;
}

.marker-animation {
  transition: 0.5s;
  transition-delay: 100ms;
}

.marker {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pointer {
  position: absolute;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  border: 4px solid #1565c0;
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.square {
  position: absolute;
  width: 50px;
  height: 50px;
  border: 4px solid #1565c0;
  border-radius: 10px;

  /* resize: both; */
  /* overflow: auto; */
}

.square.marker-selected {
  resize: both;
  overflow: auto;
}

.scenes {
  display: flex;
}

.scene {
  width: 50px;
  height: 50px;
  justify-content: center;
}

.modal {
  background-color: #1565c0;
  width: fit-content;
  color: black;
  border-radius: 10px;
  font-size: 0.85rem;
  max-width: 250px;
  color: rgb(107 114 128);
  text-align: left;
}

.modal-header {
  padding: 10px;
  background-color: #1565c0;
  color: white;
  margin: 0px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.helper {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 40px;
  display: none;
  cursor: pointer;
}

.helper:hover {
  display: block;
}

.marker:hover ~ .helper {
  display: block;
}

.navigate-button {
  height: 100px;
  position: absolute !important;
  bottom: 5%;
}

.navigate-button-next {
  right: 10px;
}

.navigate-button-prev {
  left: 10px;
}

#montage-name-input {
  font-size: 32px !important;
}
