#edit-text-modal {
  text-align: left;

  label {
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: left;
  }

  div[role='combobox'] {
    text-align: left;
  }
}
