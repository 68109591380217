.pricing {
  padding: 50px 0;
  text-align: center;
}

.pricing .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pricing h2 {
  margin-bottom: 40px;
  font-size: 2.5em;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.card {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1;
  width: 200px;
}

.card h3 {
  margin-top: 0;
  font-size: 1.5em;
}

.card .price {
  font-size: 2em;
  margin: 20px 0;
}

.card ul {
  list-style: none;
  padding: 0;
}

.card ul li {
  margin: 10px 0;
}

.card .btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.card .btn:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  .pricing-cards {
    display: flex;
    flex-direction: column;
  }
  .card {
    width: 100%;
  }
}
